import { DataRowsT, DataSortingT, HeaderColumnT } from "./tablesType";
import s from "./tablesData.module.scss";
export const headerColumn: HeaderColumnT[] = [
  { columnName: "", width: 50, align: "left" },
  { columnName: "units_list", align: "left" },
  { columnName: "first_name", align: "left" },
  { columnName: "email", align: "left" },
  { columnName: "phone_number", align: "left" },
  { columnName: "company_name", align: "left" },
  { columnName: "role", align: "left" },
];

export const columnExtensions: any[] = [
  { columnName: "units_list", sortingEnabled: false },
  { columnName: "role", sortingEnabled: false },
];

type MemberDataT = {
  first_name: string;
  last_name: string;
  salesforce_id: string;
  email: string;
  phone_number: string;
  company_name: string;
  role: string;
  units_list: string;
  activated: boolean;
  is_active: boolean;
};

export const dataRows: DataRowsT[] = [
  {
    name: "",
    title: "",
    getCellValue: (row: MemberDataT) => {
      const status = row.is_active && row.activated;
      let title = "";
      if (!row.is_active) {
        title = "Inactive User";
      } else if (!row.activated) {
        title = "User Not Activated";
      } else if (row.is_active && row.activated) {
        title = "Active User";
      } else {
        title = "Inactive User & Not Activated";
      }
      return (
        <div
          title={title}
          className={`${s.dot} ${status ? s.active : s.blocked}`}
        ></div>
      );
    },
  },
  {
    name: "units_list",
    title: "UNIT ID",
    getCellValue: (row: MemberDataT) =>
      row.units_list == null ? "-" : row.units_list.replace(",", ", "),
  },
  {
    name: "salesforce_id",
    title: "SalesForce ID",
    getCellValue: (row: MemberDataT) => `${row.salesforce_id ? row.salesforce_id : '-'}`,
  },
  {
    name: "first_name",
    title: "NAME",
    getCellValue: (row: MemberDataT) => `${row.first_name} ${row.last_name}`,
  },
  {
    name: "email",
    title: "EMAIL",
    getCellValue: (row: MemberDataT) => row.email,
  },
  {
    name: "phone_number",
    title: "PHONE",
    getCellValue: (row: MemberDataT) => row.phone_number,
  },
  {
    name: "company_name",
    title: "Company Name",
    getCellValue: (row: MemberDataT) => row.company_name,
  },
  {
    name: "role",
    title: "ROLE",
    getCellValue: (row: MemberDataT) =>
      row.role == null ? "-" : getCurrentNameForUI(row.role),
  },
];

function getCurrentNameForUI(role: string) {
  if (role === "Tenant") {
    return "User";
  } else if (role === "Subtenant") {
    return "Subuser";
  }
}

export const dataSorting: DataSortingT[] = [
  { columnName: "first_name", direction: "asc" },
];
